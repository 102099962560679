#signin-card {
    max-width: 35rem;
    background-color: black;
}

#signin-overlay {
    background-color: black;
    position: absolute;
    opacity: .5;
}

#signin-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}