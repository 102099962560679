#contact {
    min-height: 35rem;
    padding: 0px;
}

#contact-card {
    background: none;
    height: 100%;
    max-width: 40rem;
    margin: auto;
}