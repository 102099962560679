.fallback{
    height: 350px;
}

#fallback-div{
    background-image: url('https://images.unsplash.com/photo-1584738766473-61c083514bf4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 0.375rem;
}
#fallback-div-overlay{
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.375rem;
}

#fallback-text{
    color: white;
    font-size: 2.5rem;
}

@media screen and (max-width: 776px) {
    #properties-container {
        min-height: 20rem;
    }
    
    .properties-slide {
        height: 20rem;
        width: 100%;
    }
  }