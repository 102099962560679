#landing-page-body {
    background-color: white;
    color: black;
    min-height: 100vh;
}

.section{
    min-height: 500px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.section-divider{
    min-height: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.section-divider-text{
    font-size: 25px;
}

.landing-page-text {
    color: black;
}

.card-header-text{
    font-weight: bold;
}

.page-link{
    text-decoration: underline;
}

#outro-section{
    height: 400px;
    width: 100%;
    padding: 0;
    background-image: url('https://images.unsplash.com/photo-1582063289852-62e3ba2747f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#outro-section-overlay{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#outro-section-text{
    color: white;
    font-size: 2.5rem;
}