#hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    padding: 0;
}

#hero-text{
    font-size: 2rem;
}

#hero-subtext{
    font-size: 1.25rem;
}

.text-white{
    color: white;
}

#hero-overlay{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.3);
}