#short-header{
    width: 100%;
    height: 400px;
    padding: 0;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#short-header-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.text-white{
    color: white;
}